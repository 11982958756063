import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormControl } from "react-bootstrap";
import toast from "react-hot-toast";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../style/Header.css";

export default function Header({
  searchQuery,
  setSearchQuery,
  cart,
  handleLogout,
  setActiveCategory,
}) {
  const [userData, setUserData] = useState(null);
  const [vipLevel, setVipLevel] = useState("None"); // State để lưu cấp VIP
  const [menuOpen, setMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [latestRecharge, setLatestRecharge] = useState(null);
  const navigate = useNavigate();

  // Lấy dữ liệu người dùng và thông tin VIP khi component được mount
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setUserData(null);
        setVipLevel("None");
        return;
      }
      try {
        const [userResponse, vipResponse] = await Promise.all([
          fetch("https://savinashop.com/api/auth/verify", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          fetch("https://savinashop.com/api/users/vip-info", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        if (!userResponse.ok) throw new Error("Lỗi khi lấy thông tin user");
        if (!vipResponse.ok) throw new Error("Lỗi khi lấy thông tin VIP");

        const userData = await userResponse.json();
        const vipData = await vipResponse.json();

        setUserData({
          username: userData.username,
          email: userData.email,
          role: userData.role,
          saviPoints: userData.saviPoints || 0,
          avatar: userData.avatar || "https://savinashop.com/img/avatar-mac-dinh.png",
          id: userData._id,
        });
        setVipLevel(vipData.vipLevel); // Lưu cấp VIP
      } catch (error) {
        console.error("Lỗi khi lấy thông tin user hoặc VIP:", error.message);
        setUserData(null);
        setVipLevel("None");
      }
    };
    fetchUserData();
  }, []);

  // Lấy giao dịch nạp point gần nhất
  useEffect(() => {
    const fetchLatestRecharge = async () => {
      const token = localStorage.getItem("token");
      if (!token || !userData) return;

      try {
        const response = await fetch("https://savinashop.com/api/users/latest-recharge", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          if (response.status === 404) return;
          throw new Error("Lỗi khi lấy giao dịch gần nhất");
        }
        const data = await response.json();
        console.log(`[${new Date().toISOString()}] Giao dịch gần nhất:`, data);

        const lastNotifiedId = localStorage.getItem("lastNotifiedRechargeId");
        if (data.transactionId && data.transactionId !== lastNotifiedId) {
          if (data.status === "Thành công") {
            toast.success(`Nạp ${data.amount.toLocaleString()} VND thành công!`, { duration: 4000 });
            localStorage.setItem("lastNotifiedRechargeId", data.transactionId);
          } else if (data.status === "Thất bại") {
            toast.error("Nạp tiền thất bại!", { duration: 4000 });
            localStorage.setItem("lastNotifiedRechargeId", data.transactionId);
          }
          setLatestRecharge(data);
        }
      } catch (error) {
        console.error("Lỗi khi lấy giao dịch gần nhất:", error.message);
      }
    };

    fetchLatestRecharge();
    const interval = setInterval(fetchLatestRecharge, 10000);
    return () => clearInterval(interval);
  }, [userData]);

  // Lấy danh sách danh mục
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://savinashop.com/api/categories");
        if (!response.ok) throw new Error("Lỗi lấy danh mục");
        const data = await response.json();
        setCategories(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Lỗi khi lấy danh mục:", error.message);
      }
    };
    fetchCategories();
  }, []);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleLoginRegisterClick = () => {
    navigate("/login-register");
    setMenuOpen(false);
  };
  const handleLogoutClick = () => {
    handleLogout();
    setUserData(null);
    setVipLevel("None");
    setMenuOpen(false);
  };
  const handleLinkClick = () => setMenuOpen(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Định nghĩa biểu tượng VIP
  const vipIcons = {
    Copper: "/img/copper.png",
    Silver: "/img/silver.png",
    Gold: "/img/gold.png",
    Diamond: "/img/diamond.png",
    None: null,
  };

  return (
    <header className="header-custom shadow-sm" style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
      <div className="container d-none d-lg-flex align-items-center justify-content-between">
        <Link to="/" className="navbar-brand" onClick={handleLinkClick}>
          <img src="../img/logo.png" alt="Savinashop Logo" className="logo-custom" />
        </Link>
        <nav className="me-3">
          <Link to="/tin-tuc" className="nav-link text-white news-btn" onClick={handleLinkClick}>
            Tin tức
          </Link>
        </nav>
        <Form className="search-form flex-grow-1 me-3">
          <div className="search-wrapper position-relative">
            <i className="bi bi-search search-icon"></i>
            <FormControl
              type="text"
              placeholder="Tìm kiếm sản phẩm..."
              className="search-input"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </Form>
        <nav className="d-flex align-items-center">
          {userData ? (
            <div className="nav-link user-btn text-white d-flex align-items-center me-3 position-relative">
              <img src={userData.avatar} alt="Avatar" className="user-avatar" />
              <span className="user-name">{userData.username || userData.email}</span>
              {vipLevel !== "None" && (
                <img
                  src={vipIcons[vipLevel]}
                  alt={`VIP ${vipLevel}`}
                  className="vip-icon"
                  style={{ width: 20, height: 20, marginLeft: 8 }}
                />
              )}
              <div className="dropdown-menu-custom">
                {userData.saviPoints !== null && (
                  <div className="dropdown-item-custom points-display">
                    <i className="bi bi-coin me-2"></i> {userData.saviPoints.toLocaleString()} points
                  </div>
                )}
                <Link to="/account" className="dropdown-item-custom" onClick={handleLinkClick}>
                  <i className="bi bi-person me-2"></i> Quản lý tài khoản
                </Link>
                <Link to="/recharge" className="dropdown-item-custom" onClick={handleLinkClick}>
                  <i className="bi bi-wallet2 me-2"></i> Nạp Point
                </Link>
                <Link to="/recharge/history" className="dropdown-item-custom" onClick={handleLinkClick}>
                  <i className="bi bi-clock-history me-2"></i> Lịch sử nạp point
                </Link>
                <Link to="/orders" className="dropdown-item-custom" onClick={handleLinkClick}>
                  <i className="bi bi-receipt me-2"></i> Lịch sử mua hàng
                </Link>
                {(userData.role === "admin" || userData.role === "staff") && (
                  <Link to="/admin" className="dropdown-item-custom" onClick={handleLinkClick}>
                    <i className="bi bi-gear me-2"></i> Quản lý
                  </Link>
                )}
                <button className="dropdown-item-custom w-100 text-start" onClick={handleLogoutClick}>
                  <i className="bi bi-box-arrow-right me-2"></i> Đăng xuất
                </button>
              </div>
            </div>
          ) : (
            <button
              className="nav-link login-btn text-white d-flex align-items-center me-3"
              onClick={handleLoginRegisterClick}
            >
              <i className="bi bi-person-circle me-1"></i>
              <span>Đăng nhập / Đăng ký</span>
            </button>
          )}
          <Link
            to="/cart"
            className="nav-link desktop-cart position-relative d-flex align-items-center"
            onClick={handleLinkClick}
          >
            <i className="bi bi-cart3 me-1"></i>
            <span className="nav-label">Giỏ hàng</span>
            {cart.length > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {cart.reduce((sum, item) => sum + (item.quantity || 1), 0)}
              </span>
            )}
          </Link>
        </nav>
      </div>

      <div className="container d-lg-none mobile-header">
        <button onClick={toggleMenu} className="custom-toggler col-2">
          <i className="bi bi-list mobile-header-icon"></i>
        </button>
        <Form className="search-form col-8">
          <div className="search-wrapper position-relative">
            <i className="bi bi-search search-icon"></i>
            <FormControl
              type="text"
              placeholder="Tìm kiếm..."
              className="search-input"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </Form>
        <Link
          to="/cart"
          className="nav-link cart-icon col-2 d-flex align-items-center justify-content-center"
          onClick={handleLinkClick}
        >
          <i className="bi bi-cart3 mobile-header-icon"></i>
          {cart.length > 0 && (
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {cart.reduce((sum, item) => sum + (item.quantity || 1), 0)}
            </span>
          )}
        </Link>
      </div>

      {menuOpen && (
        <div className="mobile-menu d-lg-none">
          <nav className="mobile-nav">
            <div className="mobile-nav-group">
              <Link to="/" className="nav-link text-white" onClick={handleLinkClick}>
                Trang Chủ
              </Link>
              <Link to="/tin-tuc" className="nav-link text-white" onClick={handleLinkClick}>
                Tin tức
              </Link>
            </div>

            <div className="mobile-nav-group">
              <span className="nav-link text-white d-flex align-items-center" style={{ fontWeight: "bold" }}>
                Danh mục
              </span>
              {categories.map((category) => (
                <Link
                  key={category._id}
                  to={`/category/${encodeURIComponent(category.name)}`}
                  className="nav-link text-white d-flex align-items-center"
                  onClick={handleLinkClick}
                >
                  {category.icon && <i className={`bi ${category.icon} me-2`}></i>}
                  <span>{category.name}</span>
                </Link>
              ))}
            </div>

            {!userData ? (
              <div className="mobile-nav-group">
                <button
                  className="nav-link mobile-nav-item text-white d-flex align-items-center"
                  onClick={handleLoginRegisterClick}
                >
                  <i className="bi bi-person-circle me-1"></i>
                  <span>Đăng nhập / Đăng ký</span>
                </button>
              </div>
            ) : (
              <div className="mobile-nav-group">
                <div className="nav-link text-white d-flex align-items-center">
                  <img src={userData.avatar} alt="Avatar" className="user-avatar" />
                  <span>{userData.username || userData.email}</span>
                  {vipLevel !== "None" && (
                    <img
                      src={vipIcons[vipLevel]}
                      alt={`VIP ${vipLevel}`}
                      className="vip-icon"
                      style={{ width: 20, height: 20, marginLeft: 5 }}
                    />
                  )}
                </div>
                <div className="mobile-menu-content">
                  {userData.saviPoints !== null && (
                    <div className="mobile-menu-item text-dark">
                      <i className="bi bi-coin me-2"></i> {userData.saviPoints.toLocaleString()} points
                    </div>
                  )}
                  <Link to="/account" className="mobile-menu-item text-dark" onClick={handleLinkClick}>
                    <i className="bi bi-person me-2"></i> Quản lý tài khoản
                  </Link>
                  <Link to="/recharge" className="mobile-menu-item text-dark" onClick={handleLinkClick}>
                    <i className="bi bi-wallet2 me-2"></i> Nạp Point
                  </Link>
                  <Link to="/recharge/history" className="mobile-menu-item text-dark" onClick={handleLinkClick}>
                    <i className="bi bi-clock-history me-2"></i> Lịch sử nạp point
                  </Link>
                  <Link to="/orders" className="mobile-menu-item text-dark" onClick={handleLinkClick}>
                    <i className="bi bi-receipt me-2"></i> Lịch sử mua hàng
                  </Link>
                  {(userData.role === "admin" || userData.role === "staff") && (
                    <Link to="/admin" className="mobile-menu-item text-dark" onClick={handleLinkClick}>
                      <i className="bi bi-gear me-2"></i> Quản lý
                    </Link>
                  )}
                  <button className="mobile-menu-item text-dark w-100 text-start" onClick={handleLogoutClick}>
                    <i className="bi bi-box-arrow-right me-2"></i> Đăng xuất
                  </button>
                </div>
              </div>
            )}
          </nav>
        </div>
      )}
    </header>
  );
}