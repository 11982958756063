import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../style/AccountManagement.css";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  Button,
  TextField,
  Paper,
  Divider,
  Input,
  ListItemIcon,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import HistoryIcon from "@mui/icons-material/History";
import PaymentIcon from "@mui/icons-material/Payment";
import LockIcon from "@mui/icons-material/Lock";
import OrderHistory from "./OrderHistory";
import RechargeHistory from "./RechargeHistory";
import toast from "react-hot-toast";
import "../App.css";

export default function AccountManagement({ user }) {
  const [activeSection, setActiveSection] = useState("overview");
  const [userData, setUserData] = useState(null);
  const [vipLevel, setVipLevel] = useState("None");
  const [fullName, setFullName] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
      return;
    }
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const [userResponse, vipResponse] = await Promise.all([
          fetch("https://savinashop.com/api/users/me", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          fetch("https://savinashop.com/api/users/vip-info", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        if (!userResponse.ok) throw new Error("Lỗi khi lấy thông tin tài khoản");
        if (!vipResponse.ok) throw new Error("Lỗi khi lấy thông tin VIP");

        const userData = await userResponse.json();
        const vipData = await vipResponse.json();

        setUserData(userData);
        setVipLevel(vipData.vipLevel);
        setFullName(userData.fullName);
        setAvatarPreview(userData.avatar || "/img/avatar-mac-dinh.png");
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchUserData();
  }, [user, navigate]);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const filetypes = /jpeg|jpg|png|gif/;
      if (!filetypes.test(file.type)) {
        toast.error("Chỉ hỗ trợ JPEG, JPG, PNG hoặc GIF!");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Kích thước ảnh tối đa 5MB!");
        return;
      }
      setAvatarFile(file);
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      let avatarUrl = userData.avatar;

      if (avatarFile) {
        const formData = new FormData();
        formData.append("image", avatarFile);
        const uploadResponse = await fetch("https://savinashop.com/api/images", {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        });
        if (!uploadResponse.ok) throw new Error("Lỗi khi upload ảnh");
        const uploadData = await uploadResponse.json();
        avatarUrl = uploadData.url;
      }

      const response = await fetch("https://savinashop.com/api/users/me", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fullName, avatar: avatarUrl }),
      });
      if (!response.ok) throw new Error("Lỗi khi cập nhật thông tin");
      const updatedData = await response.json();
      setUserData(updatedData);
      setAvatarFile(null);
      toast.success("Cập nhật thông tin thành công!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Mật khẩu mới không khớp!");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("https://savinashop.com/api/users/me/password", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      });
      if (!response.ok) throw new Error("Lỗi khi đổi mật khẩu");
      await response.json();
      toast.success("Đổi mật khẩu thành công!");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const menuItems = [
    { id: "overview", label: "Tổng quan", icon: <PersonIcon /> },
    { id: "order-history", label: "Lịch sử đơn hàng", icon: <HistoryIcon /> },
    { id: "recharge-history", label: "Lịch sử nạp point", icon: <PaymentIcon /> },
    { id: "password", label: "Mật khẩu và bảo mật", icon: <LockIcon /> },
  ];

  const vipIcons = {
    Copper: "/img/copper.png",
    Silver: "/img/silver.png",
    Gold: "/img/gold.png",
    Diamond: "/img/diamond.png",
    None: null,
  };

  const vipTooltips = {
    Copper: "VIP Đồng - Nhận 1% cashback cho mỗi đơn hàng",
    Silver: "VIP Bạc - Nhận 2% cashback cho mỗi đơn hàng",
    Gold: "VIP Vàng - Nhận 3% cashback cho mỗi đơn hàng",
    Diamond: "VIP Kim cương - Nhận 5% cashback cho mỗi đơn hàng",
    None: "Chưa có cấp VIP - Nạp thêm để đạt cấp Đồng!",
  };

  if (!userData) {
    return <Box sx={{ textAlign: "center", py: 5 }}>Đang tải...</Box>;
  }

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", my: 5, pt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: "bold", mb: 4, color: "#2d3436" }}>
        Quản lý tài khoản
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ borderRadius: 2, p: 2, background: "#ff6b6b" }}>
            <List component="nav">
              {menuItems.map((item) => (
                <ListItem
                  key={item.id}
                  button
                  selected={activeSection === item.id}
                  onClick={() => setActiveSection(item.id)}
                  sx={{
                    borderRadius: 1,
                    mb: 1,
                    color: "#fff",
                    "&.Mui-selected": { backgroundColor: "#fff", color: "#ff6b6b" },
                    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                  }}
                >
                  <ListItemIcon sx={{ color: "inherit", minWidth: "40px" }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper elevation={3} sx={{ borderRadius: 2, p: 3, background: "#fff" }}>
            {activeSection === "overview" && (
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", color: "#ff6b6b" }}>
                  Tổng quan
                </Typography>
                <Divider sx={{ mb: 3, background: "#ff6b6b" }} />
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={4}>
                    <Typography><strong>Tên đăng nhập:</strong> {userData.username}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography><strong>Email:</strong> {userData.email.slice(0, 3)}***</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography><strong>Họ và tên:</strong></Typography>
                    <TextField
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography><strong>Số dư:</strong> {userData.saviPoints.toLocaleString()} point</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography><strong>Tổng tiền đã nạp:</strong> {userData.totalRecharged.toLocaleString()} point</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography><strong>Ngày tham gia:</strong> {new Date(userData.createdAt).toLocaleDateString("vi-VN")} {new Date(userData.createdAt).toLocaleTimeString("vi-VN")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className="vip-row">
                      <strong>Nhóm khách hàng:</strong>
                      <Box className="account-vip-wrapper" sx={{ ml: 1 }}>
                        {vipLevel !== "None" ? (
                          <>
                            <span className="vip-level">VIP {vipLevel}</span>
                            <img
                              src={vipIcons[vipLevel]}
                              alt={vipLevel}
                              className="vip-icon"
                            />
                            <span className="vip-tooltip">{vipTooltips[vipLevel]}</span>
                          </>
                        ) : (
                          <span>Không có</span>
                        )}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                  <Avatar src={avatarPreview} sx={{ width: 100, height: 100, mr: 3, border: "2px solid #ff6b6b" }} />
                  <Box>
                    <Button variant="contained" component="label" sx={{ mb: 2, background: "#ff6b6b", "&:hover": { background: "#ff4d4d" } }}>
                      Sửa ảnh đại diện
                      <Input type="file" hidden onChange={handleAvatarChange} accept="image/jpeg,image/jpg,image/png,image/gif" />
                    </Button>
                    <Typography variant="caption" display="block" sx={{ mb: 2, color: "#6c757d" }}>
                      Vui lòng chọn ảnh nhỏ hơn 5MB. Chỉ hỗ trợ JPEG, JPG, PNG, GIF.
                    </Typography>
                  </Box>
                </Box>
                <Button variant="contained" onClick={handleUpdateProfile} sx={{ background: "#ff6b6b", "&:hover": { background: "#ff4d4d" } }}>
                  Cập nhật
                </Button>
              </Box>
            )}
            {activeSection === "order-history" && <OrderHistory user={user} />}
            {activeSection === "recharge-history" && <RechargeHistory />}
            {activeSection === "password" && (
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", color: "#ff6b6b" }}>
                  Mật khẩu và bảo mật
                </Typography>
                <Divider sx={{ mb: 3, background: "#ff6b6b" }} />
                <TextField
                  label="Mật khẩu hiện tại"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Mật khẩu mới"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Xác nhận mật khẩu mới"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" onClick={handleChangePassword} sx={{ background: "#ff6b6b", "&:hover": { background: "#ff4d4d" } }}>
                  Đổi mật khẩu
                </Button>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}